import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Table from 'react-bootstrap/Table';
import { Player } from '../../interfaces/Player';
import { PlayerGamelog } from '../../interfaces/PlayerGamelog';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './PlayerCard.css'


const PlayerCard: React.FC<{ player: Player }> = ({ player }) => {

  const [ overUnderScore, setOverUnderScore ] = useState<number | ''>('');
  const [ selectedStat, setSelectedStat] = useState<keyof PlayerGamelog>('pts');

  const handleOverUnderScoreInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = parseFloat(value).toFixed(1);
    setOverUnderScore(value !== '' ? parseFloat(formattedValue) : '');
  }

  const statOptions = ['pts', 'reb', 'ast', 'blk', 'to', '3pt', 'pts_reb_ast', 'pts_reb', 'pts_ast']; // options for the buttons (based on interface)

  // lookup to display, ends with a dict like this => { pts: 'PTS', reb: 'REB',  ..., pts_reb_ast: 'PTS_REB_AST' }
  const statOptionsLookup = statOptions.reduce((acc, curr) => {
    acc[curr] = curr.replace(/_/g, ' + ').toUpperCase();
    return acc;
  }, {} as { [key: string]: string })

  const handleStatButtonChange = (value: string) => {
    setSelectedStat(value as keyof PlayerGamelog);

    if (selectedStat !== value) {   // only refresh the over-underscore if a different button was selected
      setOverUnderScore('')
    }
  }

  const statValueCounts = {
    over: overUnderScore !== '' ? player.player_gamelog.filter(game => game[selectedStat] > overUnderScore).length : '-',
    under: overUnderScore !== '' ? player.player_gamelog.filter(game => game[selectedStat] < overUnderScore).length : '-',
    push: overUnderScore !== '' ? player.player_gamelog.filter(game => game[selectedStat] === overUnderScore).length : '-'
  }

  return (
    <Card>
        <Card.Body>
            <Card.Title>
                { player.player_full_name }
            </Card.Title>
            <Card.Subtitle className='mb-4'>
            { player.team_city } { player.team_name }
            </Card.Subtitle>

            <Card.Text as="div">
              <div>
                <div className='mb-2'>
                  { Object.entries(statOptionsLookup).map((statOptions) => (
                    <ToggleButton
                      id={statOptions[0] + player.player_full_name}
                      key={statOptions[0]}
                      type='checkbox'
                      variant={selectedStat === statOptions[0] ? 'dark' : 'outline-dark'}
                      checked={selectedStat === statOptions[0]}
                      onClick={() => handleStatButtonChange(statOptions[0])}
                      value={statOptions[0]}
                      className='btn-sm mb-2 me-2'
                    >
                      { statOptions[1] }
                    </ToggleButton>
                  )) }
                </div>

                <Form className='mb-2'>
                  <Form.Group controlId='basicText'>
                    <Form.Control
                      type='number'
                      placeholder='Over-under value'
                      onChange={ handleOverUnderScoreInputChange }
                      value={ overUnderScore }
                      step='0.5'
                      >
                    </Form.Control>
                  </Form.Group>
                </Form>
              </div>
            </Card.Text>

          {/* Display over-under score counter. Object.entries(statValueCounts) looks like [['over', '-'], ['under', '-'], ['push', '-']]
          */}
          <div className='mb-2'>
              <Row>
              {
              Object.entries(statValueCounts).map((statValueCount) => (
              <Col key={statValueCount[0]}>
                <aside className='counter-badge border rounded'>
                  <h2 className={ `counter-badge-title ${statValueCount[0]}-color` }>
                    { statValueCount[0].toUpperCase() }
                  </h2>
                  <p className='counter-badge-content'>
                    { statValueCount[1] }
                  </p>
                </aside>
                </Col>
              ))
            }
              </Row>
            </div>

            <div>
              <Table size='sm' bordered striped={ overUnderScore === '' ? true : false } style={{ fontSize: '12px' }}>
                <thead>
                  <tr>
                    <th>GAME DATE</th>
                    <th>MATCHUP</th>
                    <th>{ statOptionsLookup[selectedStat] }</th>
                  </tr>
                </thead>
                <tbody>
                  {player.player_gamelog.map((game, indx) => (
                    <tr
                      key={indx}
                      className={ overUnderScore !== '' ?
                      (game[selectedStat] === overUnderScore ? 'table-warning' :
                      (game[selectedStat] > overUnderScore ? 'table-success' : 'table-danger')) : 'table-default' }
                    >
                      <td style={{ textAlign: 'left' }}>{game.game_date_display}</td>
                      <td style={{ textAlign: 'left' }}>{game.matchup}</td>
                      <td style={{ textAlign: 'right' }}>{game[selectedStat]}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

            </div>
        </Card.Body>
    </Card>
  )
}

export default PlayerCard
