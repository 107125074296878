import React, { useEffect, useState } from 'react';
import PlayerCard from '../PlayerCard/PlayerCard';
import { Container, Row, Col } from 'react-bootstrap';
import { Player } from '../../interfaces/Player';


const PlayersList: React.FC = () => {
    const [players, setPlayers] = useState<Player[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value)
    }

    const filteredPlayers = players.filter((player) =>
        player.player_full_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        const fetchPlayers = async() => {
            try {
                const s3File = 'https://dev-sports-data.s3.us-east-2.amazonaws.com/egress/player_gamelog_data.json'
                const response = await fetch(s3File)

                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                const result = await response.json();

                setPlayers(result as Player[])
                setLoading(false);
            } catch (err) {
                setError('Error fetching players');
                setLoading(false);
            }
        };

        fetchPlayers();
    }, []);

    if (loading) return <p>Loading...</p>
    if (error) return <p>{error}</p>

    return (
        <>
        <header className="App-header mb-4">
            NBA Last 10 games
            <div className='mb-2'>
                Season: 2023-24
            </div>
            <input
                type='text'
                placeholder='Search for a player'
                id='player-search'
                value={searchQuery}
                onChange={handleSearch}
            ></input>
            </header>
        <Container>
            <Row className='gy-4'>
            {filteredPlayers.map(player => (
                <Col key={ player.player_id } xs={12} sm={6} md={41} lg={3}>
                    <PlayerCard player={ player }></PlayerCard>
                </Col>
                ))}
            </Row>
        </Container>
        </>
    );
};


export default PlayersList
